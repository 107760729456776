import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Hero, HeroHeading, HeroLead, HeroButtons, HeroButton } from "src/components/Hero";
import { Features, Feature, FeatureHeading, FeatureText } from "src/components/Features";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Hero mdxType="Hero">
	<HeroHeading mdxType="HeroHeading">Another Todo App</HeroHeading>
	<HeroLead mdxType="HeroLead">
		Glue Stack is a task app for teams powered by React, Java, MySQL and
		Kubernetes. Oh, and some{" "}
		<strong>
			<em>serious glue</em>
		</strong>
		.
	</HeroLead>
	<HeroButtons mdxType="HeroButtons">
		<HeroButton href="https://tasks.cadell.dev/#/signup" variant="primary" mdxType="HeroButton">
			Signup now!
		</HeroButton>
		<HeroButton href="https://tasks.cadell.dev" mdxType="HeroButton">Login</HeroButton>
	</HeroButtons>
    </Hero>
    <Features mdxType="Features">
	<Feature mdxType="Feature">
		<FeatureHeading mdxType="FeatureHeading">Easy to Use</FeatureHeading>
		<FeatureText mdxType="FeatureText">
			Glue Stack is designed to nail the basics. That means mobile-first design,
			performance and consistency. Functionality like Search, Filters and
			Sorting come standard.
		</FeatureText>
	</Feature>
	<Feature mdxType="Feature">
		<FeatureHeading mdxType="FeatureHeading">Scalable Development</FeatureHeading>
		<FeatureText mdxType="FeatureText">
			Documentation, Docker and Tests maximise the developer experience.
			Continuously Deploy with GitHub Actions, zero-downtime deploys, a staging
			environment, and feature toggles (coming soon).
		</FeatureText>
	</Feature>
	<Feature mdxType="Feature">
		<FeatureHeading mdxType="FeatureHeading">Scalable Performance</FeatureHeading>
		<FeatureText mdxType="FeatureText">
			Deployed on Kubernetes means high-availability, scaling and self-healing.
			It also means less vendor lockin and a platform for future (micro)
			services. It currently costs ~$60 usd/month on Google Cloud.
		</FeatureText>
	</Feature>
    </Features>
    <p><img alt="Screenshot" src={require("../assets/screenshot.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      